import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import "./Poker.css";
import { Navbar, Footer, Ranking } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";

function Poker() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="futsalPage" style={{ backgroundColor: theme.tertiary }}>
      <Helmet>
        <title>POKER | AAFA</title>
      </Helmet>
      <Navbar />
      <div
        className="futsalPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.tertiary }}>♤ ♧ POKER AAFA ♡ ♢</h1>
      </div>
      <Ranking />
      <Footer />
    </div>
  );
}

export default Poker;
