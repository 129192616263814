import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { VscChromeClose } from "react-icons/vsc";
import {
  MdOutlineDoNotDisturbOn,
  MdOutlineCancel,
  MdOutlineCancelPresentation,
} from "react-icons/md";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { IoMdFootball } from "react-icons/io";
import { GiTrophy } from "react-icons/gi";
import { TfiFaceSad } from "react-icons/tfi";
import { Link } from "react-router-dom";
import "./PartidaCard.css";
import axios from "axios";

function PartidaCard({
  id,
  tela,
  time1,
  time2,
  golsTime1,
  golsTime2,
  day,
  artilheiro,
  artilheiroFoto,
  artilheiroTime,
  mvp,
  mvpTime,
  pior,
  piorTime,
  // historicoTime1,
  // historicoTime2,
  escalacaoTime1,
  escalacaoTime2,
  escalacaoAmbos,
  vezesMvp,
  vezesPior,
  nomeArtilheiros,
  vezesArtilheiros,
  idArtilheiros,
  idMvp,
  idPior,
}) {
  const [artilheiros, setArtilheiros] = useState([]);
  const [fotoMvp, setFotoMvp] = useState(null);
  const [fotoPior, setFotoPior] = useState(null);
  const [fotosJogadoresTime1, setFotosJogadoresTime1] = useState({});
  const [fotosJogadoresTime2, setFotosJogadoresTime2] = useState({});
  const [fotosJogadoresAmbos, setFotosJogadoresAmbos] = useState({});
  const [fotosArtilheiros, setFotosArtilheiros] = useState({});
  const { theme } = useContext(ThemeContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fotoDey =
    "https://images2.minutemediacdn.com/image/upload/c_fill,w_360,ar_1:1,f_auto,q_auto,g_auto/shape/cover/sport/agif19110517475737_crop1620159562487_242310155-aea57a7d47cb72ed01cbfc57ca3fdafb.jpg"
  // const getIconeHistory = (history, index) => {
  //   switch (history) {
  //     case "DERROTA":
  //       return <MdOutlineCancel key={index} className="redGols" />;
  //     case "EMPATE":
  //       return (
  //         <MdOutlineDoNotDisturbOn
  //           key={index}
  //           style={{ color: theme.tertiary }}
  //         />
  //       );
  //     case "VITORIA":
  //       return <RiCheckboxCircleLine key={index} className="greenGols" />;
  //     default:
  //       return null;
  //   }
  // };
  useEffect(() => {
    const fetchFotos = async () => {
      const novasFotos = {};
      for (const player of escalacaoAmbos) {
        const foto = await getFotoById(player.id_jogador, "player");
        novasFotos[player.id_jogador] = foto;
      }
      setFotosJogadoresAmbos(novasFotos);
    };

    if (escalacaoAmbos?.length > 0) {
      fetchFotos();
    }
  }, [escalacaoAmbos]);

  useEffect(() => {
    const fetchFotos = async () => {
      const novasFotos = {};
      const ids = idArtilheiros.split(",");
      for (const player of ids) {
        const foto = await getFotoById(player, "player");
        novasFotos[player] = foto;
      }
      setFotosArtilheiros(novasFotos);
    };

    if (idArtilheiros?.length > 0) {
      fetchFotos();
    }
  }, [idArtilheiros]);

  useEffect(() => {
    const fetchFotos = async () => {
      const novasFotos = {};
      for (const player of escalacaoTime2) {
        const foto = await getFotoById(player.id_jogador, "player");
        novasFotos[player.id_jogador] = foto;
      }
      setFotosJogadoresTime2(novasFotos);
    };

    if (escalacaoTime2?.length > 0) {
      fetchFotos();
    }
  }, [escalacaoTime2]);

  useEffect(() => {
    const fetchFotos = async () => {
      const novasFotos = {};
      for (const player of escalacaoTime1) {
        const foto = await getFotoById(player.id_jogador, "player");
        novasFotos[player.id_jogador] = foto;
      }
      setFotosJogadoresTime1(novasFotos);
    };

    if (escalacaoTime1?.length > 0) {
      fetchFotos();
    }
  }, [escalacaoTime1]);

  const getFotoById = async (idJogador, destaque) => {
    try {
      const res = await axios.get(
        `${apiUrl}/jogador/getFotoJogador/${idJogador}`,
        {}
      );

      if (!res.data[0]?.jof_foto_64 || res.data?.length === 0) {
        return null;
      }

      const fotoFormatted = `data:image/jpeg;base64,${res.data[0]?.jof_foto_64}`;
      if (destaque === "mvp") {
        return setFotoMvp(fotoFormatted);
      }
      if (destaque === "pior") {
        return setFotoPior(fotoFormatted);
      }
      return fotoFormatted;
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    if (idMvp) {
      getFotoById(idMvp, "mvp");
      getFotoById(idPior, "pior");
    }
    setArtilheiros(artilheiro);

    if (nomeArtilheiros) {
      const nomes = nomeArtilheiros.split(",");
      const vezes = vezesArtilheiros.split(",");
      const ids = idArtilheiros.split(",");
      const artilheirosData = nomes.map((nome, index) => ({
        nome,
        vezesArtilheiro: parseInt(vezes[index], 10),
        id: parseInt(ids[index], 10),
      }));

      setArtilheiros(artilheirosData);
    }
  }, [artilheiro]);

  return (
    <Link
      key={id}
      to={`/futsal-cpj/partida/${id}`}
      className={`card-partida-main tela-${tela} ${window.location.pathname}`}
    >
      <div key={id} className={`card ${theme.type} ${tela}`}>
        <div style={{ color: theme.primary }} className="times times-mob">
          <div className="time1">
            <h2>{time1}</h2>
            <h2
              className={`${
                golsTime1 > golsTime2
                  ? "greenGols"
                  : golsTime1 < golsTime2
                  ? "redGols"
                  : ""
              }`}
            >
              {golsTime1}
            </h2>
          </div>
          <div className="vs">
            <VscChromeClose />
          </div>
          <div className="time2">
            <h2>{time2}</h2>
            <h2
              className={`${
                golsTime1 > golsTime2
                  ? "redGols"
                  : golsTime1 < golsTime2
                  ? "greenGols"
                  : ""
              }`}
            >
              {golsTime2}
            </h2>
          </div>
        </div>
        <h3 style={{ color: theme.primary }}>{day}</h3>
        {escalacaoTime1 && (
          <>
            <div className="players">
              <div className="players-time1">
                {escalacaoTime1?.map((player) => (
                  <p key={player.id} style={{ color: theme.tertiary }}>
                    <span className="p-foto">
                      <img
                        src={fotosJogadoresTime1[player.id_jogador] ?? fotoDey}
                        alt="player"
                      />{" "}
                      {player.jogadorNome}
                    </span>
                    <span className="p-icones">
                      {Array(player.gols)
                        .fill()
                        .map((_, index) => (
                          <IoMdFootball key={index} />
                        ))}
                      {Array(player.faltas)
                        .fill()
                        .map((_, index) => (
                          <MdOutlineCancelPresentation key={index} />
                        ))}{" "}
                    </span>
                  </p>
                ))}
              </div>
              <div className="players-time2">
                {escalacaoTime2?.map((player) => (
                  <p key={player.id} style={{ color: theme.tertiary }}>
                    <span className="p-foto">
                      <img
                        src={fotosJogadoresTime2[player.id_jogador] ?? fotoDey}
                        alt="player"
                      />{" "}
                      {player.jogadorNome}
                    </span>
                    <span className="p-icones">
                      {Array(player.gols)
                        .fill()
                        .map((_, index) => (
                          <IoMdFootball key={index} />
                        ))}
                      {Array(player.faltas)
                        .fill()
                        .map((_, index) => (
                          <MdOutlineCancelPresentation key={index} />
                        ))}
                    </span>
                  </p>
                ))}
              </div>
            </div>
            <div className="players-ambos">
              {escalacaoAmbos?.map((player) => (
                <p key={player.id} style={{ color: theme.tertiary }}>
                  <span className="p-foto">
                    <img
                      src={fotosJogadoresAmbos[player.id_jogador] ?? fotoDey}
                      alt="player"
                    />{" "}
                    {player.jogadorNome}
                  </span>
                  <span className="p-icones">
                    {Array(player.gols)
                      .fill()
                      .map((_, index) => (
                        <IoMdFootball key={index} />
                      ))}
                    {Array(player.faltas)
                      .fill()
                      .map((_, index) => (
                        <MdOutlineCancelPresentation key={index} />
                      ))}
                  </span>
                </p>
              ))}
            </div>
          </>
        )}
        {tela !== "principal" && <h2>DESTAQUES</h2>}
        {artilheiros && artilheiros.length > 0 ? (
          artilheiros.map((art, index) => (
            <h4
              key={index}
              style={{ color: theme.tertiary }}
              className={`lado-${artilheiroTime} lado-destaques`}
            >
              Artilheiro <IoMdFootball />{" "}
              <img
                src={fotosArtilheiros[art.id] ?? fotoDey}
                alt="player"
              />{" "}
              {art.nome} ({art.vezesArtilheiro}x)
            </h4>
          ))
        ) : (
          <p>Nenhum artilheiro disponível</p>
        )}
        <h6
          style={{ color: theme.tertiary }}
          className={`lado-${mvpTime} lado-destaques`}
        >
          Melhor do Jogo <GiTrophy />{" "}
          <img src={fotoMvp ?? fotoDey} alt="player" /> {mvp} ({vezesMvp}x)
        </h6>
        {/* <h5
          style={{ color: theme.tertiary }}
          className={`lado-${piorTime} lado-destaques`}
        >
          Pior do Jogo <TfiFaceSad />{" "}
          <img src={fotoPior ?? fotoDey} alt="player" /> {pior} ({vezesPior}x)
        </h5> */}
        {/* <div className="history">
          <div className="history-esquerda">
            {historicoTime1?.map((resultado, index) =>
              getIconeHistory(resultado, index)
            )}
          </div>
          <div className="history-direita">
            {historicoTime2?.map((resultado, index) => getIconeHistory(resultado, index))}
          </div>
        </div> */}
      </div>
    </Link>
  );
}

export default PartidaCard;
