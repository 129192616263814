import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import "./JogoCPJ.css";
import { Navbar, Footer } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { FaArrowLeft } from "react-icons/fa6";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { VscChromeClose } from "react-icons/vsc";
import { FiPlusCircle } from "react-icons/fi";
import { GrSubtractCircle } from "react-icons/gr";
import Modal from "@material-ui/core/Modal";
import JogoModalCPJ from "./JogoModalCPJ.js";
import JogoModalFinishCPJ from "./JogoModalFinishCPJ.js";
import { CgCloseR } from "react-icons/cg";

function JogoCPJ() {
  const { theme } = useContext(ThemeContext);
  const { idPartida } = useParams();
  const [jogo, setJogo] = useState({});
  const [success, setSuccess] = useState(false);
  const [addOrRemove, setAddOrRemove] = useState(false);
  const [time, setTime] = useState("");
  const [golOrFalta, setGolOrFalta] = useState("");
  const [msg, setMsg] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  // const token = localStorage.getItem("tokenSplash");
  const [open, setOpen] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);

  const handleOpenModal = (addOrRemove, time, golOrFalta) => {
    setAddOrRemove(addOrRemove);
    setTime(time);
    setGolOrFalta(golOrFalta);
    setOpen(true);
  };

  const handleCloseModal = () => {
    getPartidaById();
    setOpen(false);
    setOpenFinish(false);
  };

  const getPartidaById = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/partida/getJogo/${idPartida}`,
        {}
      );

      setJogo(res.data[0]);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getPartidaById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitGame = async (e) => {
    e.preventDefault();
    setOpenFinish(true);
    return console.log("FINALIZAR PARTIDA");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <>
      <div
        className={`JogoCPJ ${theme.type}`}
        style={{ backgroundColor: theme.secondary }}
      >
        <Helmet>
          <title>Futsal | CPJ</title>
        </Helmet>
        <Navbar />
        <div
          className="JogoCPJ-header"
          style={{ backgroundColor: theme.primary }}
        >
          <h1 style={{ color: theme.secondary }}>⚽️ JOGO 🥅</h1>
        </div>
        <div className="JogoCPJ-container">
          <div className="futsal-cont">
            <div className="header-new">
              <h2 style={{ color: theme.tertiary }} className="header-title">
                Partida em Andamento ⏱
              </h2>
              <Link to="/futsal-cpj" className="link-voltar">
                <button
                  className={"voltar"}
                  style={{
                    backgroundColor: theme.primary,
                    color: theme.secondary,
                  }}
                >
                  <FaArrowLeft className={"voltar-icone"} />
                  Voltar para todas as partidas
                </button>
              </Link>
            </div>
          </div>
          <div className="new-card">
            <form
              onSubmit={handleSubmitGame}
              className="form-container"
              style={{ color: theme.tertiary }}
            >
              <div className="row">
                <div className="time1">
                  <h2>{jogo?.par_time1}</h2>
                  <h3>Gols ⚽️</h3>
                  <h2
                    className={`gols ${
                      (jogo?.par_gols_time1 ?? 0) > (jogo?.par_gols_time2 ?? 0)
                        ? "greenGols"
                        : (jogo?.par_gols_time1 ?? 0) <
                          (jogo?.par_gols_time2 ?? 0)
                        ? "redGols"
                        : ""
                    }`}
                  >
                    <button
                      className="button-less"
                      type="button"
                      onClick={() =>
                        handleOpenModal(false, jogo?.par_time1, "GOL")
                      }
                    >
                      <GrSubtractCircle />
                    </button>
                    {jogo?.par_gols_time1 ?? 0}
                    <button
                      className="button-plus"
                      type="button"
                      onClick={() =>
                        handleOpenModal(true, jogo?.par_time1, "GOL")
                      }
                    >
                      <FiPlusCircle />
                    </button>
                  </h2>
                  <h3>Faltas 🚫</h3>
                  <h3 className="faltas">
                    <button
                      className="button-less"
                      type="button"
                      onClick={() =>
                        handleOpenModal(false, jogo?.par_time1, "FALTA")
                      }
                    >
                      <GrSubtractCircle />
                    </button>
                    {jogo?.par_faltas_time1 ?? 0}
                    <button
                      className="button-plus"
                      type="button"
                      onClick={() =>
                        handleOpenModal(true, jogo?.par_time1, "FALTA")
                      }
                    >
                      <FiPlusCircle />
                    </button>
                  </h3>
                </div>
                <div className="vs vs-jogo">
                  <VscChromeClose />
                </div>
                <div className="time2">
                  <h2>{jogo?.par_time2}</h2>
                  <h3>Gols ⚽️</h3>
                  <h2
                    className={`gols ${
                      (jogo?.par_gols_time1 ?? 0) > (jogo?.par_gols_time2 ?? 0)
                        ? "redGols"
                        : (jogo?.par_gols_time1 ?? 0) <
                          (jogo?.par_gols_time2 ?? 0)
                        ? "greenGols"
                        : ""
                    }`}
                  >
                    <button
                      className="button-less"
                      type="button"
                      onClick={() =>
                        handleOpenModal(false, jogo?.par_time2, "GOL")
                      }
                    >
                      <GrSubtractCircle />
                    </button>
                    {jogo?.par_gols_time2 ?? 0}
                    <button
                      className="button-plus"
                      type="button"
                      onClick={() =>
                        handleOpenModal(true, jogo?.par_time2, "GOL")
                      }
                    >
                      <FiPlusCircle />
                    </button>
                  </h2>
                  <h3>Faltas 🚫</h3>
                  <h3 className="faltas">
                    <button
                      className="button-less"
                      type="button"
                      onClick={() =>
                        handleOpenModal(false, jogo?.par_time2, "FALTA")
                      }
                    >
                      <GrSubtractCircle />
                    </button>
                    {jogo?.par_faltas_time2 ?? 0}
                    <button
                      className="button-plus"
                      type="button"
                      onClick={() =>
                        handleOpenModal(true, jogo?.par_time2, "FALTA")
                      }
                    >
                      <FiPlusCircle />
                    </button>
                  </h3>
                </div>
              </div>
              <div className="button-row">
                <button type="submit" className="save-button">
                  Finalizar Partida
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          action={
            <React.Fragment>
              <IconButton
                size="medium"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          style={{
            backgroundColor:
              msg?.includes("Token") ||
              msg?.includes("existe") ||
              msg?.includes("Informe")
                ? " #590c0c"
                : "green",
            color: "white",
            fontSize: "1rem",
            fontFamily: "var(--primaryFont)",
          }}
          message={msg}
        />
      </Snackbar>
      <Modal open={open} onClose={handleCloseModal}>
        <div
          className="box-modal"
          style={{ backgroundColor: theme.secondary, color: theme.tertiary }}
        >
          <button className="btn-close" onClick={() => setOpen(false)}>
            <CgCloseR />
          </button>
          <JogoModalCPJ
            addOrRemove={addOrRemove}
            golOrFalta={golOrFalta}
            time={time}
            onCloseModal={handleCloseModal}
            idJogo={idPartida}
          />
        </div>
      </Modal>
      <Modal open={openFinish} onClose={handleCloseModal}>
        <div
          className="box-modal box-checks"
          style={{ backgroundColor: theme.secondary, color: theme.tertiary }}
        >
          <button className="btn-close" onClick={() => setOpenFinish(false)}>
            <CgCloseR />
          </button>
          <JogoModalFinishCPJ
            theme={theme}
            time1={jogo?.par_time1}
            time2={jogo?.par_time2}
            golsTime1={jogo?.par_gols_time1 ?? 0}
            golsTime2={jogo?.par_gols_time2 ?? 0}
            onCloseModal={handleCloseModal}
            idJogo={idPartida}
          />
        </div>
      </Modal>
    </>
  );
}

export default JogoCPJ;
