import sazuPhoto from "../assets/png/players-cpj/sazu.jpg";

export const partidaSextaData = [
  {
    id: 1,
    time1: "JUVENTUS",
    time2: "BETIS",
    escalacaoTime1: [
      {
        id: 1,
        jogadorNome: "Matheus Goulart",
        jogadorFoto: null,
        gols: 5,
        faltas: 1,
      },
      {
        id: 2,
        jogadorNome: "Thiago Pinheiro",
        jogadorFoto: null,
        gols: 8,
        faltas: 2,
      },
      {
        id: 3,
        jogadorNome: "Lucas Silva",
        jogadorFoto: null,
        gols: 4,
        faltas: 3,
      },
      {
        id: 4,
        jogadorNome: "Bruno Almeida",
        jogadorFoto: null,
        gols: 2,
        faltas: 0,
      },
      {
        id: 5,
        jogadorNome: "Pedro Santos",
        jogadorFoto: null,
        gols: 5,
        faltas: 1,
      },
      {
        id: 6,
        jogadorNome: "Gabriel Lima",
        jogadorFoto: null,
        gols: 7,
        faltas: 2,
      },
      {
        id: 7,
        jogadorNome: "Felipe Costa",
        jogadorFoto: null,
        gols: 3,
        faltas: 4,
      },
      {
        id: 8,
        jogadorNome: "Ricardo Alves",
        jogadorFoto: null,
        gols: 6,
        faltas: 2,
      },
      {
        id: 9,
        jogadorNome: "João Oliveira",
        jogadorFoto: null,
        gols: 1,
        faltas: 1,
      },
    ],
    escalacaoTime2: [
      {
        id: 10,
        jogadorNome: "Carlos Pereira",
        jogadorFoto: null,
        gols: 9,
        faltas: 0,
      },
      {
        id: 11,
        jogadorNome: "André Mendes",
        jogadorFoto: null,
        gols: 3,
        faltas: 2,
      },
      {
        id: 12,
        jogadorNome: "Rafael Souza",
        jogadorFoto: null,
        gols: 7,
        faltas: 1,
      },
      {
        id: 13,
        jogadorNome: "Diego Araújo",
        jogadorFoto: null,
        gols: 5,
        faltas: 3,
      },
      {
        id: 14,
        jogadorNome: "Victor Martins",
        jogadorFoto: null,
        gols: 2,
        faltas: 2,
      },
      {
        id: 15,
        jogadorNome: "Marcelo Lima",
        jogadorFoto: null,
        gols: 4,
        faltas: 0,
      },
      {
        id: 16,
        jogadorNome: "Railan Éisso",
        jogadorFoto: null,
        gols: 6,
        faltas: 1,
      },
      {
        id: 17,
        jogadorNome: "Henrique Borges",
        jogadorFoto: null,
        gols: 8,
        faltas: 3,
      },
    ],
    golsTime1: 14,
    golsTime2: 12,
    day: "06/09/2024",
    artilheiro: "Matheus Goulart",
    artilheiroFoto: sazuPhoto,
    artilheiroTime: "time1",
    mvp: "Lucas Pikunas",
    mvpFoto: sazuPhoto,
    mvpTime: "time1",
    pior: "Rafael Ianke",
    piorFoto: sazuPhoto,
    piorTime: "time2",
    historicoTime1: [
      "DERROTA",
      "VITORIA",
      "EMPATE",
      "VITORIA",
      "VITORIA",
      "DERROTA",
      "VITORIA",
      "EMPATE",
      "VITORIA",
      "VITORIA",
    ],
    historicoTime2: [
      "VITORIA",
      "DERROTA",
      "EMPATE",
      "DERROTA",
      "DERROTA",
      "VITORIA",
      "DERROTA",
      "EMPATE",
      "DERROTA",
      "DERROTA",
    ].reverse(),
  },
  {
    id: 2,
    time1: "AMARELO",
    time2: "PRETO",
    piorTime: "time1",
    artilheiroTime: "time2",
    mvpTime: "time2",
    golsTime1: 10,
    golsTime2: 10,
    day: "13/09/2024",
    artilheiro: "João Silva",
    artilheiroFoto: sazuPhoto,
    mvp: "Lucas Pikunas",
    mvpFoto: sazuPhoto,
    pior: "Carlos",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "VITORIA", "DERROTA", "EMPATE", "VITORIA"],
    historicoTime2: ["EMPATE", "DERROTA", "VITORIA", "VITORIA", "EMPATE"],
  },
  {
    id: 3,
    time1: "AMARELO",
    time2: "PRETO",
    piorTime: "time2",
    artilheiroTime: "time2",
    mvpTime: "time1",
    golsTime1: 7,
    golsTime2: 5,
    day: "20/09/2024",
    artilheiro: "Pedro Mendes",
    artilheiroFoto: sazuPhoto,
    mvp: "Mass Gui",
    mvpFoto: sazuPhoto,
    pior: "Felipe",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "EMPATE", "DERROTA", "VITORIA", "VITORIA"],
    historicoTime2: ["DERROTA", "DERROTA", "VITORIA", "EMPATE", "DERROTA"],
  },
  {
    id: 4,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 9,
    piorTime: "time1",
    artilheiroTime: "time1",
    mvpTime: "time2",
    golsTime2: 6,
    day: "27/09/2024",
    artilheiro: "Lucas Costa",
    artilheiroFoto: sazuPhoto,
    mvp: "Sazu Futsal",
    mvpFoto: sazuPhoto,
    pior: "Ricardo",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "VITORIA", "DERROTA", "DERROTA", "VITORIA"],
    historicoTime2: ["DERROTA", "EMPATE", "DERROTA", "VITORIA", "EMPATE"],
  },
];
