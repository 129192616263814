import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./NewCPJ.css";
import { Navbar, Footer } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { FaArrowLeft } from "react-icons/fa6";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";

function NewCPJ() {
  const { theme } = useContext(ThemeContext);
  const [foto, setFoto] = useState(null);
  const [teamPlayer, setTeamPlayer] = useState("");
  const [nome, setNome] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [date, setDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("tokenSplash");

  const handleSubmitGame = async (e) => {
    // if (!token) {
    //   setSuccess(true);
    //   return setMsg("Informe a senha.");
    // }
    e.preventDefault();
    try {
      const res = await axios.post(
        `${apiUrl}/partida`,
        {
          par_time1: team1.toUpperCase(),
          par_time2: team2.toUpperCase(),
          par_dia_semana: dayOfWeek.toUpperCase(),
          par_data: date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(true);
      return setMsg(res.data.msg);
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        return setMsg(response.data);
      }
      setMsg(response.data.msg);
    }
  };

  const handleSubmitPlayer = async (e) => {
    e.preventDefault();

    const data = {
      jog_nome: nome.toUpperCase(),
      jog_time: teamPlayer.toUpperCase(),
    };

    if (foto) {
      // Converter a imagem para base64
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result.split(",")[1]);
          reader.onerror = (error) => reject(error);
        });

      data.jog_foto = await toBase64(foto);
    }
    try {
      const res = await axios.post(
        `${apiUrl}/jogador`,

        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(true);
      return setMsg(res.data.msg);
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        return setMsg(response.data);
      }
      setMsg(response.data.msg);
    }
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFoto(file);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <>
      <div className="newCPJ" style={{ backgroundColor: theme.secondary }}>
        <Helmet>
          <title>Futsal | CPJ</title>
        </Helmet>
        <Navbar />
        <div
          className="newCPJ-header"
          style={{ backgroundColor: theme.primary }}
        >
          <h1 style={{ color: theme.secondary }}>⚽️ NEW ✢ </h1>
        </div>
        <div className="newCPJ-container">
          <div className="futsal-cont">
            <div className="header-new">
              <h2 style={{ color: theme.tertiary }}>Cadastrar Partida</h2>
              <Link to="/futsal-cpj" className="link-voltar">
                <button
                  className={"voltar"}
                  style={{
                    backgroundColor: theme.primary,
                    color: theme.secondary,
                  }}
                >
                  <FaArrowLeft className={"voltar-icone"} />
                  Voltar para todas as partidas
                </button>
              </Link>
            </div>
          </div>
          <div className="new-card">
            <form onSubmit={handleSubmitGame} className="form-container">
              <div className="row">
                <div className="input-group">
                  <label htmlFor="team1">TIME 1:</label>
                  <select
                    id="team1"
                    value={team1}
                    onChange={(e) => setTeam1(e.target.value)}
                    className="new-input"
                  >
                    <option value="">Selecione</option>
                    <option value="amarelo">Amarelo</option>
                    <option value="azul">Azul</option>
                  </select>
                </div>

                <div className="input-group">
                  <label htmlFor="team2">TIME 2:</label>
                  <select
                    id="team2"
                    value={team2}
                    onChange={(e) => setTeam2(e.target.value)}
                    className="new-input"
                  >
                    <option value="">Selecione</option>
                    <option value="preto">Preto</option>
                    <option value="sem-colete">Sem-Colete</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="input-group">
                  <label htmlFor="dayOfWeek">DIA DA SEMANA:</label>
                  <select
                    id="dayOfWeek"
                    value={dayOfWeek}
                    onChange={(e) => setDayOfWeek(e.target.value)}
                    className="new-input"
                  >
                    <option value="">Selecione</option>
                    <option value="sexta">Sexta</option>
                    <option value="sabado">Sábado</option>
                  </select>
                </div>

                <div className="input-group">
                  <label htmlFor="date">DATA DA PARTIDA:</label>
                  <input
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="new-input"
                  />
                </div>
              </div>

              <div className="button-row">
                <button type="submit" className="save-button">
                  Criar Partida
                </button>
              </div>
            </form>
          </div>
          <div className="futsal-cont">
            <div className="header-new">
              <h2 style={{ color: theme.tertiary }}>Cadastrar Jogador</h2>
            </div>
          </div>
          <div className="new-card">
            <form onSubmit={handleSubmitPlayer} className="form-container">
              <div className="row">
                <div className="input-group">
                  <label htmlFor="nome">NOME DO JOGADOR:</label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                    className="new-input"
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="teamPlayer">TIME QUE JOGA:</label>
                  <select
                    id="teamPlayer"
                    value={teamPlayer}
                    onChange={(e) => setTeamPlayer(e.target.value)}
                    className="new-input"
                  >
                    <option value="">Selecione</option>
                    <option value="time1">Time 1</option>
                    <option value="time2">Time 2</option>
                    <option value="ambos">Ambos</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="input-group">
                  <label htmlFor="foto">Foto do Jogador:</label>
                  <input
                    type="file"
                    id="foto"
                    name="foto"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFotoChange}
                    className="form-input"
                  />
                </div>
              </div>

              <div className="button-row">
                <button type="submit" className="save-button">
                  Cadastrar Jogador
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          action={
            <React.Fragment>
              <IconButton
                size="medium"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          style={{
            backgroundColor:
              msg?.includes("Token") ||
              msg?.includes("existe") ||
              msg?.includes("Informe")
                ? " #590c0c"
                : "green",
            color: "white",
            fontSize: "1rem",
            fontFamily: "var(--primaryFont)",
          }}
          message={msg}
        />
      </Snackbar>
    </>
  );
}

export default NewCPJ;
