export { default as Navbar } from './Navbar/Navbar'
export { default as Footer } from './Footer/Footer'
export { default as Landing } from './Landing/Landing'
export { default as About } from './About/About'
export { default as Skills } from './Skills/Skills'
export { default as Testimonials } from './Testimonials/Testimonials'
export { default as Blog } from './Blog/Blog'
export { default as SingleBlog } from './Blog/SingleBlog/SingleBlog'
export { default as Education } from './Education/Education'
export { default as Experience } from'./Experience/Experience'
export { default as Contacts } from'./Contacts/Contacts'
export { default as ContactsFeedback } from'./Contacts/ContactsFeedback'
export { default as Projects } from'./Projects/Projects'
export { default as Services } from'./Services/Services'
export { default as SingleProject } from './Projects/SingleProject/SingleProject'
export { default as SingleService } from './Services/SingleService/SingleService'
export { default as Achievement } from './Achievement/Achievement'
export { default as BackToTop } from'./BackToTop/BackToTop'
export { default as PartidaCard } from'./PartidaCard/PartidaCard'
export { default as Ranking } from'./Ranking/Ranking'