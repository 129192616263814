import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./MatheusJuliana.css";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import Marquee from "react-fast-marquee";

function MatheusJuliana() {
  const { theme } = useContext(ThemeContext);
  const [tempoJuntos, setTempoJuntos] = useState("");
  const dataInicial = new Date("2021-01-02T00:00:00");

  useEffect(() => {
    const calcularTempo = () => {
      const agora = new Date();
      let diff = agora - dataInicial;

      const anos = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
      diff -= anos * 1000 * 60 * 60 * 24 * 365;

      const meses = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
      diff -= meses * 1000 * 60 * 60 * 24 * 30;

      const dias = Math.floor(diff / (1000 * 60 * 60 * 24));
      diff -= dias * 1000 * 60 * 60 * 24;

      const horas = Math.floor(diff / (1000 * 60 * 60));
      diff -= horas * 1000 * 60 * 60;

      const minutos = Math.floor(diff / (1000 * 60));
      diff -= minutos * 1000 * 60;

      const segundos = Math.floor(diff / 1000);

      setTempoJuntos(
        `${anos} anos ${meses} meses ${dias} dias ` +
          `${horas} horas ${minutos} min ${segundos} s`
      );
    };

    calcularTempo();

    const interval = setInterval(() => {
      calcularTempo();
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skillBoxStyle = {
    backgroundColor: theme.secondary,
    boxShadow: `0px 0px 30px ${theme.primary30}`,
  };

  return (
    <div className="amorPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Matheus ❤️ Juliana</title>
      </Helmet>
      <div
        className="amorPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>Matheus ❤️ Juliana</h1>

        <h2 style={{ color: theme.secondary }}>{tempoJuntos}</h2>
      </div>
      <div className="amorPage-container">
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="amor--scroll">
          <Marquee
            gradient={false}
            speed={200}
            pauseOnHover={false}
            pauseOnClick={true}
            delay={0}
            play={true}
            direction="right"
          >
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/448272509_3076907765779579_5168293809955268912_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeGC6GSQomuIddqF3xPvEgdYAr4ep1hki98Cvh6nWGSL35xV1clJ0rW7o4XZSOrcYdFbb1w7ANmqLWwz-XptZtbh&_nc_ohc=to10xGO55r4Q7kNvgFtqEZk&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=AM3ZfPZO6tCsZ1mxgsC9jem&oh=00_AYBjeb2mtr4vikfDQ8lBd8KtOsGDavrieDnplbMbfiI_ug&oe=66F3F975"
                alt="Matheus e Juliana"
              />
            </div>

            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/382597812_2891578540979170_5773227568758263160_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeFozZziV0dk8LBbMGo9ID8Uafm9MLhCrZNp-b0wuEKtk8uzpbFedcNwjIWxK8HBYxrtAAIXnXY1BByI_HT7Dz5s&_nc_ohc=3iczrLCUr9YQ7kNvgHDKU23&_nc_ht=scontent.fbfh13-1.fna&oh=00_AYAjvvkSZT1C8tOLDiUp6IsrCIizdA2fXd2UikjdaeoKuA&oe=66F3EF29"
                alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/289835876_2507867552683606_2550313485067295617_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEnUyZJGRq-Io9pPHsUkiI6OEiXJs0RGUE4SJcmzREZQYcjj8OrBaxvl7Zb8T5g7WJFprvlUBANoCsuPhaUbUDA&_nc_ohc=EU6opqEHU5cQ7kNvgEPTd09&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=ASjlQVhgsrCQPiAzyMoY6hP&oh=00_AYDBj22eTepGOk883MVbinyrpVyblYqn_jLStN8t-eFZ3g&oe=66F3F263" alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/448392336_3076907769112912_3786249880293841616_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeGorSordBM8tyAederwseghvmFmMiyisZK-YWYyLKKxkrXLikbtIQoZj9T-rFm1jxD03Knt5Ig3OoHpZs65J0aD&_nc_ohc=E-JH9bDuQhIQ7kNvgFpiHIp&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=AWQhVq8RJA8XKuSgXOqQNnk&oh=00_AYB2y6huYRJf9GYLzGMOxaTeyfYXiGTHeswHpLB8IKKx_A&oe=66F40ABC"
                alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/448397523_3076907775779578_6053957091101660082_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeFZdt4yjqWqtJy5jIH2ugztqWBFdvfc8bypYEV299zxvIX_vbyZ7Cj6djA5yqkVum0ikU_vZJTS3GbPbqqZWZqS&_nc_ohc=NDQtrJpwu8oQ7kNvgFQNaxL&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=AuMqM_QhJZSp1vCMm_cjK-d&oh=00_AYAoQHy1Zif_oIF7xUd5E3tL4nOZSUJkdtvJdEAZlkKT_Q&oe=66F4208A"
                alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/318713508_2654414741362219_5992908069926763092_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeGZk0K9O4_cDvXfzLdZPkLfF9gABLSKV6kX2AAEtIpXqWXSz6FDbEvKW5yVVNS9INTPZmZJJMD3czBn57yzfSas&_nc_ohc=7SneU98KQRQQ7kNvgG0nJeB&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=Ahb7xQ6b7nlLUjuvRFa6aiz&oh=00_AYDFaRrp9I3XEX36IQY7mJfsug_WuWUurVxV4xNma2a3-g&oe=66F4223A"
                alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/270472701_2371588499644846_225251907882543724_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeFVICNy_82tv8OwG3J-uLYblfHelxLF2DGV8d6XEsXYMbCm-MdolGSksdqid_pRaOMNsiwQIlHhBW24l9tr0ype&_nc_ohc=fKU7BoKWABUQ7kNvgGqHaSQ&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=Aq4wuz9ja9UZr_-ytSC4FM4&oh=00_AYCNIhFL90G8uPRt5bMDuvCREZnASY7ijmOpcyXHrxIxZg&oe=66F404CB"
                alt="Matheus e Juliana"
              />
            </div>
            <div className="amor--box" style={skillBoxStyle}>
              <img
                src="https://scontent.fbfh13-1.fna.fbcdn.net/v/t39.30808-6/278077358_2447489182054777_4403869136862358629_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeF1fQYf99YSy68VHNO79vLlXSJIc0zhFthdIkhzTOEW2M0IHoGuaePNRcZgY9WOijMMeJjB5o0FM2vr8va0qaZm&_nc_ohc=HrbpTVnhtUAQ7kNvgFLFFrk&_nc_ht=scontent.fbfh13-1.fna&_nc_gid=AsbDbmca-JlNMCmUgC2q3fp&oh=00_AYDG2OT3BV3eZSVBH-WwrO9LbMXxv7W0aOfpHU9DGKRKUg&oe=66F3F2AD"
                alt="Matheus e Juliana"
              />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default MatheusJuliana;
