import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./FutsalCPJ.css";
import { Navbar, Footer, PartidaCard, Ranking } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { partidaSextaData } from "../../data/partidaSextaData";
import { Link } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import axios from "axios";

function FutsalCPJ() {
  const [partidasSexta, setPartidasSexta] = useState(partidaSextaData);
  const [partidasSabado, setPartidasSabado] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { theme } = useContext(ThemeContext);

  const getPartida = async () => {
    try {
      const res = await axios.get(`${apiUrl}/partida/getPartidas`, {});
      setPartidasSexta(res.data.partidaSexta);
      setPartidasSabado(res.data.partidaSabado);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getPartida();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="futsalPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Futsal | CPJ</title>
      </Helmet>
      <Navbar />
      <div
        className="futsalPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>⚽️ FUTSAL CPJ 🥅</h1>
      </div>
      <div className="futsalPage-container">
        {/* <div className="futsalPage-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pesquisar por data"
            className={classes.search}
          />
        </div> */}
        {/* <Link to="/futsal-cpj/cadastros" className="link-voltar">
          <button
            className={"voltar"}
            style={{
              backgroundColor: theme.primary,
              color: theme.secondary,
            }}
          >
            <BsPlusLg className={"voltar-icone"} />
            Cadastrar
          </button>
        </Link> */}
        <div className="futsal-cont">
          <div className="sexta">
            <h2 style={{ color: theme.tertiary }}>SEXTA-FEIRA</h2>
            {partidasSexta.map((game) => (
              <PartidaCard
                tela="principal"
                key={game.id}
                id={game.id}
                time1={game.par_time1}
                time2={game.par_time2}
                golsTime1={game.par_gols_time1}
                golsTime2={game.par_gols_time2}
                day={game.data_partida}
                artilheiro={[]}
                idArtilheiros={game.par_id_artilheiro}
                idMvp={game.par_id_mvp}
                idPior={game.par_id_pior_jogador}
                nomeArtilheiros={game.nome_artilheiros}
                vezesArtilheiros={game.vezes_artilheiros}
                artilheiroFoto={game.foto_artilheiros}
                vezesArtilheiro={game.vezes_artilheiros}
                mvp={game.nome_mvp}
                mvpFoto={game.foto_mvp}
                vezesMvp={game.vezes_mvp}
                pior={game.nome_pior}
                piorFoto={game.foto_pior}
                vezesPior={game.vezes_pior}
                historicoTime1={game.historicoTime1}
                historicoTime2={game.historicoTime2}
              />
            ))}
          </div>
          <div className="sabado">
            <h2 style={{ color: theme.tertiary }}>SÁBADO</h2>
            {partidasSabado.map((game) => (
              <PartidaCard
                tela="principal"
                key={game.id}
                id={game.id}
                time1={game.par_time1}
                time2={game.par_time2}
                golsTime1={game.par_gols_time1}
                golsTime2={game.par_gols_time2}
                day={game.data_partida}
                artilheiro={[]}
                idArtilheiros={game.par_id_artilheiro}
                idMvp={game.par_id_mvp}
                idPior={game.par_id_pior_jogador}
                nomeArtilheiros={game.nome_artilheiros}
                vezesArtilheiros={game.vezes_artilheiros}
                artilheiroFoto={game.foto_artilheiros}
                vezesArtilheiro={game.vezes_artilheiros}
                mvp={game.nome_mvp}
                mvpFoto={game.foto_mvp}
                vezesMvp={game.vezes_mvp}
                pior={game.nome_pior}
                piorFoto={game.foto_pior}
                vezesPior={game.vezes_pior}
                historicoTime1={game.historicoTime1}
                historicoTime2={game.historicoTime2}
              />
            ))}
          </div>
        </div>
      </div>
      <Ranking />
      <Footer />
    </div>
  );
}

export default FutsalCPJ;
