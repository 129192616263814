import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./JogoCPJ.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function JogoModalFinishCPJ({
  idJogo,
  theme,
  onCloseModal,
  golsTime1,
  golsTime2,
  time1,
  time2,
}) {
  const [success, setSuccess] = useState(false);
  const [jogadoresAusentes, setJogadoresAusentes] = useState([]);
  const [selectedJogadorMVP, setSelectedJogadorMVP] = useState(null);
  const [selectedJogadorPior, setSelectedJogadorPior] = useState(null);
  const [selectedGoleiro1, setSelectedGoleiro1] = useState(null);
  const [selectedGoleiro2, setSelectedGoleiro2] = useState(null);
  const [fotoJogadorMVP, setFotoJogadorMVP] = useState(null);
  const [fotoJogadorPior, setFotoJogadorPior] = useState(null);
  const [fotoGoleiro1, setFotoGoleiro1] = useState(null);
  const [fotoGoleiro2, setFotoGoleiro2] = useState(null);
  const [jogadoresTodos, setJogadoresTodos] = useState([]);
  const [fotosPlayers, setFotosPlayers] = useState({});
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("tokenSplash");
  const fotoDey =
    "https://zeiro.com.br/wp-content/uploads/2024/06/Deyverson-aceita-defender-o-Corinthians-e-torcida-do-Cuiaba-fica-em-choque--e1717462917181.jpg";

  const handleCheckboxChange = (event, jogadorId) => {
    if (event.target.checked) {
      setJogadoresAusentes((prev) => [...prev, jogadorId]);
    } else {
      setJogadoresAusentes((prev) => prev.filter((id) => id !== jogadorId));
    }
  };

  const handleSelectMVP = (event) => {
    const jogadorId = event.target.value;
    let jogadorSelecionadoMVP = jogadoresTodos.find(
      (jogador) => jogador.id === parseInt(jogadorId)
    );

    setSelectedJogadorMVP(jogadorSelecionadoMVP);
    getFotoById(jogadorSelecionadoMVP.id, "mvp");
  };

  const handleSelectPior = (event) => {
    const jogadorId = event.target.value;
    let jogadorSelecionadoPior = jogadoresTodos.find(
      (jogador) => jogador.id === parseInt(jogadorId)
    );

    setSelectedJogadorPior(jogadorSelecionadoPior);
    getFotoById(jogadorSelecionadoPior.id, "pior");
  };

  const handleSelectGoleiro1 = (event) => {
    const jogadorId = event.target.value;
    let goleiro1 = jogadoresTodos.find(
      (jogador) => jogador.id === parseInt(jogadorId)
    );

    setSelectedGoleiro1(goleiro1);
    getFotoById(goleiro1.id, "goleiro1");
  };

  const handleSelectGoleiro2 = (event) => {
    const jogadorId = event.target.value;
    let goleiro2 = jogadoresTodos.find(
      (jogador) => jogador.id === parseInt(jogadorId)
    );

    setSelectedGoleiro2(goleiro2);
    getFotoById(goleiro2.id, "goleiro2");
  };

  const handleSubmitScore = async (e) => {
    e.preventDefault();

    if (!selectedJogadorMVP || !selectedJogadorPior) {
      setSuccess(true);
      return setMsg("Selecione o melhor jogador e pior jogador.");
    }

    if (!selectedGoleiro1 || !selectedGoleiro2) {
      setSuccess(true);
      return setMsg("Selecione os goleiros.");
    }

    const escalacao = jogadoresTodos.filter(
      (jogador) => !jogadoresAusentes.includes(jogador.id)
    );

    const bodyFinalizarJogo = {
      escalacao: escalacao,
      par_id_mvp: selectedJogadorMVP.id,
      par_id_pior_jogador: selectedJogadorPior.id,
      par_id_goleiro1: selectedGoleiro1.id,
      par_id_goleiro2: selectedGoleiro2.id,
      par_status: "FINALIZADA",
      par_empate: golsTime1 === golsTime2 ? 1 : 0,
    };

    try {
      const res = await axios.put(
        `${apiUrl}/partida/${idJogo}`,
        bodyFinalizarJogo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(true);
      setMsg(res.data.msg);
      setTimeout(() => {
        onCloseModal();
        history.push("/futsal-cpj");
      }, "2000");

      return;
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        return setMsg(response.data);
      }
      setMsg(response.data.msg);
    }
  };

  const getFotoById = async (idJogador, tipo = "") => {
    try {
      const res = await axios.get(
        `${apiUrl}/jogador/getFotoJogador/${idJogador}`,
        {}
      );

      if (!res.data[0]?.jof_foto_64 || res.data?.length === 0) {
        return null;
      }

      const fotoFormatted = `data:image/jpeg;base64,${res.data[0]?.jof_foto_64}`;
      if (tipo === "mvp") {
        setFotoJogadorMVP(fotoFormatted);
      }
      if (tipo === "pior") {
        setFotoJogadorPior(fotoFormatted);
      }
      if (tipo === "goleiro1") {
        setFotoGoleiro1(fotoFormatted);
      }
      if (tipo === "goleiro2") {
        setFotoGoleiro2(fotoFormatted);
      }
      return fotoFormatted;
    } catch ({ response }) {
      console.error(response);
    }
  };

  const getJogadores = async () => {
    try {
      const res = await axios.get(`${apiUrl}/jogador/getJogadores`, {});
      setJogadoresTodos(res.data);
      const fotos = {};

      await Promise.all(
        res.data.map(async (jogador) => {
          const foto = await getFotoById(jogador.id);
          if (foto) {
            fotos[jogador.id] = foto;
          }
        })
      );
      setFotosPlayers(fotos);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getJogadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <>
      <h2>FINALIZAR PARTIDA</h2>
      <h2>MARQUE OS JOGADORES QUE NÃO ESTAVAM PRESENTES:</h2>
      <form onSubmit={handleSubmitScore} className="form-modal modal-check">
        {jogadoresTodos?.length > 0 && (
          <div className="row checks">
            {jogadoresTodos?.map((jogador) => (
              <div key={jogador.id} className="checkbox-group">
                <input
                  type="checkbox"
                  id={`jogador-${jogador.id}`}
                  value={jogador.id}
                  className="check-players"
                  onChange={(e) => handleCheckboxChange(e, jogador.id)}
                />
                <img
                  className="img-jogador img-check"
                  src={fotosPlayers[jogador.id] ?? fotoDey}
                  alt="player"
                />
                <label
                  style={{ color: theme.tertiary }}
                  htmlFor={`jogador-${jogador.id}`}
                >
                  {jogador.jog_nome}
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="row">
          <div className="input-group">
            <label htmlFor="selectJogador" className="label-modal">
              SELECIONE O DESTAQUE DA PARTIDA:
            </label>
            <select
              id="selectJogador"
              onChange={handleSelectMVP}
              className="new-input"
            >
              <option value="">Selecione</option>
              {jogadoresTodos?.map((jogador) => (
                <option key={jogador.id} value={jogador.id}>
                  {jogador.jog_nome}
                </option>
              ))}
            </select>
            {selectedJogadorMVP && (
              <div className="img-select">
                <img
                  className="img-jogador"
                  src={fotoJogadorMVP ?? fotoDey}
                  alt="player"
                />
                <p>
                  {" "}
                  JOGADOR DESTAQUE:{" "}
                  <strong>{selectedJogadorMVP.jog_nome}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="input-group">
            <label htmlFor="selectJogador" className="label-modal">
              SELECIONE O PIOR DA PARTIDA:
            </label>
            <select
              id="selectJogador"
              onChange={handleSelectPior}
              className="new-input"
            >
              <option value="">Selecione</option>
              {jogadoresTodos?.map((jogador) => (
                <option key={jogador.id} value={jogador.id}>
                  {jogador.jog_nome}
                </option>
              ))}
            </select>
            {selectedJogadorPior && (
              <div className="img-select">
                <img
                  className="img-jogador"
                  src={fotoJogadorPior ?? fotoDey}
                  alt="player"
                />
                <p>
                  JOGADOR BAGRE: <strong>{selectedJogadorPior.jog_nome}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="input-group">
            <label htmlFor="selectJogador" className="label-modal">
              SELECIONE GOLEIRO DO TIME {time1}
            </label>
            <select
              id="selectJogador"
              onChange={handleSelectGoleiro1}
              className="new-input"
            >
              <option value="">Selecione</option>
              {jogadoresTodos?.map((jogador) => (
                <option key={jogador.id} value={jogador.id}>
                  {jogador.jog_nome}
                </option>
              ))}
            </select>
            {selectedGoleiro1 && (
              <div className="img-select">
                <img
                  className="img-jogador"
                  src={fotoGoleiro1 ?? fotoDey}
                  alt="player"
                />
                <p>
                  GOLEIRO DO {time1}:{" "}
                  <strong>{selectedGoleiro1.jog_nome}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="input-group">
            <label htmlFor="selectJogador" className="label-modal">
              SELECIONE GOLEIRO DO TIME {time2}
            </label>
            <select
              id="selectJogador"
              onChange={handleSelectGoleiro2}
              className="new-input"
            >
              <option value="">Selecione</option>
              {jogadoresTodos?.map((jogador) => (
                <option key={jogador.id} value={jogador.id}>
                  {jogador.jog_nome}
                </option>
              ))}
            </select>
            {selectedGoleiro2 && (
              <div className="img-select">
                <img
                  className="img-jogador"
                  src={fotoGoleiro2 ?? fotoDey}
                  alt="player"
                />
                <p>
                  GOLEIRO DO {time2}:{" "}
                  <strong>{selectedGoleiro2.jog_nome}</strong>
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="button-row">
          <button type="submit" className="save-button">
            SALVAR
          </button>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          action={
            <React.Fragment>
              <IconButton
                size="medium"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          style={{
            backgroundColor:
              msg?.includes("Token") ||
              msg?.includes("encontrado") ||
              msg?.includes("Selecione") ||
              msg?.includes("existe") ||
              msg?.includes("Informe")
                ? " #590c0c"
                : "green",
            color: "white",
            fontSize: "1rem",
            fontFamily: "var(--primaryFont)",
          }}
          message={msg}
        />
      </Snackbar>
    </>
  );
}

export default JogoModalFinishCPJ;
