export { default as Main } from './Main/Main'
export { default as BlogPage } from './Blog/BlogPage'
export { default as ProjectPage } from './Project/ProjectPage'
export { default as ExperiencePage } from './Experience/ExperiencePage'
export { default as FeedbackPage } from './Feedback/FeedbackPage'
export { default as FutsalCPJ } from './FutsalCPJ/FutsalCPJ'
export { default as PartidaCPJ } from './PartidaCPJ/PartidaCPJ'
export { default as NewCPJ } from './NewCPJ/NewCPJ'
export { default as JogoCPJ } from './JogoCPJ/JogoCPJ'
export { default as MatheusJuliana } from './MatheusJuliana/MatheusJuliana'
export { default as Poker } from './Poker/Poker'