import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PartidaCPJ.css";
import { Navbar, Footer, PartidaCard } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { FaArrowLeft } from "react-icons/fa6";
import axios from "axios";
// import { partidaSextaData } from "../../data/partidaSextaData.js";

function PartidaCPJ() {
  const { theme } = useContext(ThemeContext);
  const { idPartida } = useParams();
  const [partidaInfo, setPartidaInfo] = useState([]);
  const [escalacaoTime1, setEscalacaoTime1] = useState([]);
  const [escalacaoTime2, setEscalacaoTime2] = useState([]);
  const [escalacaoAmbos, setEscalacaoAmbos] = useState([]);
  const [artilheiros, setArtilheiros] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  // const [partidaInfo, sePartidaInfo] = useState(
  //   partidaSextaData[idPartida - 1]
  // );

  const getPartida = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/partida/getPartidas/${idPartida}`,
        {}
      );
      setPartidaInfo(res.data);

      const nomes = res.data.nome_artilheiros.split(",");
      const vezes = res.data.vezes_artilheiros.split(",");
      const ids = res.data.par_id_artilheiro.split(",");
      const artilheirosData = nomes.map((nome, index) => ({
        nome,
        vezesArtilheiro: parseInt(vezes[index], 10),
        id: parseInt(ids[index], 10)
      }));
      
      setArtilheiros(artilheirosData);
    } catch ({ response }) {
      console.error(response);
    }
  };

  const getEscalacao = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/jogador/getEscalacao/${idPartida}`,
        {}
      );
      setEscalacaoTime1(res.data.time1);
      setEscalacaoTime2(res.data.time2);
      setEscalacaoAmbos(res.data.ambos);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getPartida();
    getEscalacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="partidaPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Futsal | CPJ</title>
      </Helmet>
      <Navbar />
      <div
        className="partidaPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>⚽️ PARTIDA 🥅</h1>
      </div>
      <div className="partidaPage-container">
        <div className="futsal-cont">
          <div className="sexta card-interno">
            <div className="header-partida">
              <h2 style={{ color: theme.tertiary }}>
                {partidaInfo.par_dia_semana === "SABADO"
                  ? "SÁBADO"
                  : partidaInfo.par_dia_semana}{" "}
                🔸 {partidaInfo.data_partida}
              </h2>
              <Link to="/futsal-cpj" className="link-voltar">
                <button
                  className={"voltar voltar-mob"}
                  style={{
                    backgroundColor: theme.primary,
                    color: theme.secondary,
                  }}
                >
                  <FaArrowLeft className={"voltar-icone"} />
                  Voltar para todas as partidas
                </button>
              </Link>
            </div>
            <PartidaCard
              tela="partida"
              key={partidaInfo.id_jogador}
              id={partidaInfo.id_jogador}
              time1={partidaInfo.par_time1}
              time2={partidaInfo.par_time2}
              escalacaoTime1={escalacaoTime1}
              escalacaoTime2={escalacaoTime2}
              escalacaoAmbos={escalacaoAmbos}
              golsTime1={partidaInfo.par_gols_time1}
              golsTime2={partidaInfo.par_gols_time2}
              day={partidaInfo.data_partida}
              idArtilheiros={partidaInfo.par_id_artilheiro}
              idMvp={partidaInfo.par_id_mvp}
              idPior={partidaInfo.par_id_pior_jogador}
              artilheiro={artilheiros}
              artilheiroFoto={partidaInfo.artilheiroFoto}
              mvp={partidaInfo.nome_mvp}
              mvpFoto={partidaInfo.mvpFoto}
              vezesMvp={partidaInfo.vezes_mvp}
              pior={partidaInfo.nome_pior}
              piorFoto={partidaInfo.piorFoto}
              vezesPior={partidaInfo.vezes_pior}
              historicoTime1={partidaInfo.historicoTime1}
              historicoTime2={partidaInfo.historicoTime2}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PartidaCPJ;
