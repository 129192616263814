import React, { useState } from "react";
import corinthians from "../../assets/png/poker/corin.png";
import oper from "../../assets/png/poker/oper.png";
import thi from "../../assets/png/poker/thi.png";
import robson from "../../assets/png/poker/robson.png";
import thomas from "../../assets/png/poker/thomas.png";
import math from "../../assets/png/poker/math.png";
import fla from "../../assets/png/poker/fla.png";
import ninguem from "../../assets/png/poker/ninguem.png";
import "./Ranking.css";

const RankBox = ({ players }) => {
  return (
    <div className="rank-container">
      {players.map((player, index) => (
        <div className="rank-box" key={index}>
          <img
            className="team-logo"
            src={player.teamLogo}
            alt={`${player.playerName} team logo`}
          />
          <img
            className="player-image"
            src={player.playerImage}
            alt={`${player.playerName}`}
          />
          <div className="ranking-container">
            <h1 className="ranking"> #{player.ranking} </h1>
          </div>
          <h1 className="player-name">{player.playerName}</h1>
          <h2 className="player-infos">Pontos: {player.pontos}</h2>
          <h2 className="player-infos s2">Stack: {player.stack}</h2>
          <h2 className="player-infos s3">Fichas: {player.fichas}</h2>
        </div>
      ))}
    </div>
  );
};

const Ranking = () => {
  const [players, setPlayers] = useState([
    {
      teamLogo: corinthians,
      playerImage: thi,
      ranking: "1",
      playerName: "Thiago",
      pontos: 7300,
      stack: "100%",
      fichas: "20.000",
    },
    {
      teamLogo: oper,
      playerImage: robson,
      ranking: "2",
      playerName: "Robson",
      pontos: 6500,
      stack: "89%",
      fichas: "17.800",
    },
    {
      teamLogo: corinthians,
      playerImage: math,
      ranking: "3",
      playerName: "Matheus",
      pontos: 5700,
      stack: "78%",
      fichas: "15.600",
    },
    {
      teamLogo: fla,
      playerImage: thomas,
      ranking: "4",
      playerName: "Thomas",
      pontos: 5500,
      stack: "75%",
      fichas: "15.100",
    },
    {
      teamLogo: corinthians,
      playerImage: ninguem,
      ranking: "5",
      playerName: "Vinicius",
      pontos: 4900,
      stack: "67%",
      fichas: "13.400",
    },
    {
      teamLogo: oper,
      playerImage: ninguem,
      ranking: "6",
      playerName: "Willian",
      pontos: 3800,
      stack: "52%",
      fichas: "10.400",
    },
    {
      teamLogo: fla,
      playerImage: ninguem,
      ranking: "7",
      playerName: "Eduardo",
      pontos: 2900,
      stack: "40%",
      fichas: "7.900",
    },
    {
      teamLogo: corinthians,
      playerImage: ninguem,
      ranking: "8",
      playerName: "Igor",
      pontos: 2700,
      stack: "37%",
      fichas: "7.400",
    },
    {
      teamLogo: corinthians,
      playerImage: ninguem,
      ranking: "9",
      playerName: "Raphael",
      pontos: 2500,
      stack: "34%",
      fichas: "6.800",
    },
    {
      teamLogo: corinthians,
      playerImage: ninguem,
      ranking: "10",
      playerName: "André",
      pontos: 2100,
      stack: "29%",
      fichas: "5.800",
    },
    {
      teamLogo: fla,
      playerImage: ninguem,
      ranking: "8",
      playerName: "Igor",
      pontos: 1400,
      stack: "19%",
      fichas: "3.800",
    },
  ]);

  return <RankBox players={players} />;
};

export default Ranking;
