import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./JogoCPJ.css";
import axios from "axios";

function JogoModalCPJ({ addOrRemove, golOrFalta, time, idJogo, onCloseModal }) {
  const [success, setSuccess] = useState(false);
  const [jogadoresTime1, setJogadoresTime1] = useState([]);
  const [jogadoresTime2, setJogadoresTime2] = useState([]);
  const [selectedJogador, setSelectedJogador] = useState(null);
  const [jogadorSelectFoto, setJogadorSelectFoto] = useState(null);
  const [foto64, setFoto64] = useState("");
  const [msg, setMsg] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("tokenSplash");
  const fotoDey =
    "https://zeiro.com.br/wp-content/uploads/2024/06/Deyverson-aceita-defender-o-Corinthians-e-torcida-do-Cuiaba-fica-em-choque--e1717462917181.jpg";
  const handleSelect = (event) => {
    const jogadorId = event.target.value;
    let jogadorSelecionado = jogadoresTime1.find(
      (jogador) => jogador.id === parseInt(jogadorId)
    );
    if (!jogadorSelecionado) {
      jogadorSelecionado = jogadoresTime2.find(
        (jogador) => jogador.id === parseInt(jogadorId)
      );
    }
    setSelectedJogador(jogadorSelecionado);
  };

  const getFotoById = async (idJogador) => {
    try {
      const res = await axios.get(
        `${apiUrl}/jogador/getFotoJogador/${idJogador}`,
        {}
      );

      if (!res.data[0]?.jof_foto_64 || res.data?.length === 0) {
        return;
      }

      const fotoFormatted = `data:image/jpeg;base64,${res.data[0]?.jof_foto_64}`;
      setJogadorSelectFoto(fotoFormatted);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    console.log("pegue a foto do jogador ", selectedJogador?.id);
    if (selectedJogador?.id) {
      getFotoById(selectedJogador?.id);
    }
  }, [selectedJogador]);

  const getJogadores = async () => {
    const time1 = [];
    const time2 = [];

    try {
      const res = await axios.get(`${apiUrl}/jogador/getJogadores`, {});

      res.data.forEach((jogador) => {
        jogador.jog_foto_64 = `data:image/jpeg;base64,${jogador.jog_foto_64}`;

        if (jogador.jog_time === "TIME1" || jogador.jog_time === "AMBOS") {
          time1.push(jogador);
        }
        if (jogador.jog_time === "TIME2" || jogador.jog_time === "AMBOS") {
          time2.push(jogador);
        }
      });

      setJogadoresTime1(time1);
      setJogadoresTime2(time2);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getJogadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const addScorePlayer = async () => {
    let dataBodyPlayer = {
      gfs_tipo: golOrFalta,
      gfs_id_partida: +idJogo,
      gfs_id_jogador: selectedJogador.id,
      gfs_time: time === "PRETO" || time === "SEM-COLETE" ? "TIME2" : "TIME1",
    };

    try {
      const res = await axios.post(`${apiUrl}/golsFaltas`, dataBodyPlayer, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccess(true);
      setMsg(res.data.msg);
      return res.data.msg;
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        setMsg(response.data);
        return response.data;
      }
      setMsg(response.data.msg);
      return response.data.msg;
    }
  };

  const removeScorePlayer = async () => {
    let dataBodyPlayer = {
      gfs_tipo: golOrFalta,
      gfs_id_partida: +idJogo,
      gfs_id_jogador: selectedJogador.id,
      gfs_time: time === "PRETO" || time === "SEM-COLETE" ? "TIME2" : "TIME1",
    };

    try {
      const res = await axios.put(
        `${apiUrl}/golsFaltas/delete`,
        dataBodyPlayer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(true);
      setMsg(res.data.msg);
      return res.data.msg;
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        setMsg(response.data);
        return response.data;
      }
      setMsg(response.data.msg);
      return response.data.msg;
    }
  };

  const handleSubmitScore = async (e) => {
    e.preventDefault();
    let dataBody = {
      score: true,
      par_gols_time1: 0,
      par_gols_time2: 0,
      par_faltas_time1:
        (time === "AMARELO" || time === "AZUL") && addOrRemove
          ? 1
          : (time === "AMARELO" || time === "AZUL") && !addOrRemove
          ? -1
          : 0,
      par_faltas_time2:
        (time === "PRETO" || time === "SEM-COLETE") && addOrRemove
          ? 1
          : (time === "PRETO" || time === "SEM-COLETE") && !addOrRemove
          ? -1
          : 0,
    };
    if (golOrFalta === "GOL") {
      dataBody = {
        score: true,
        par_gols_time1:
          (time === "AMARELO" || time === "AZUL") && addOrRemove
            ? 1
            : (time === "AMARELO" || time === "AZUL") && !addOrRemove
            ? -1
            : 0,
        par_gols_time2:
          (time === "PRETO" || time === "SEM-COLETE") && addOrRemove
            ? 1
            : (time === "PRETO" || time === "SEM-COLETE") && !addOrRemove
            ? -1
            : 0,
        par_faltas_time1: 0,
        par_faltas_time2: 0,
      };
    }

    let msg = "";

    if (!addOrRemove) {
      msg = await removeScorePlayer();
    } else {
      msg = await addScorePlayer();
    }

    if (typeof msg === "string" && msg.includes("encontrado")) {
      return setMsg(msg);
    }

    try {
      const res = await axios.put(`${apiUrl}/partida/${idJogo}`, dataBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccess(true);
      setMsg(res.data.msg);
      setTimeout(() => {
        onCloseModal();
      }, "2000");

      return;
    } catch ({ response }) {
      setSuccess(true);
      if (typeof response.data === "string") {
        return setMsg(response.data);
      }
      setMsg(response.data.msg);
    }
  };

  return (
    <>
      <h2>
        {addOrRemove
          ? `Selecione qual jogador do time ${time} `
          : "Tem certeza que deseja REMOVER"}
      </h2>
      <h2>
        {golOrFalta === "GOL" && addOrRemove
          ? "marcou o GOL: "
          : golOrFalta === "FALTA" && addOrRemove
          ? "cometeu a FALTA:"
          : golOrFalta === "GOL" && !addOrRemove
          ? ` um GOL do time ${time}? `
          : ` uma FALTA do time ${time}? `}
      </h2>
      <form onSubmit={handleSubmitScore} className="form-modal">
        {jogadoresTime1?.length > 0 &&
          (time === "AMARELO" || time === "AZUL") && (
            <div className="row">
              <div className="input-group">
                <label htmlFor="selectJogador" className="label-modal">
                  SELECIONE O JOGADOR:
                </label>
                <select
                  id="selectJogador"
                  onChange={handleSelect}
                  className="new-input"
                >
                  <option value="">Selecione</option>
                  {jogadoresTime1?.map((jogador) => (
                    <option key={jogador.id} value={jogador.id}>
                      {jogador.jog_nome}
                    </option>
                  ))}
                </select>
                {selectedJogador && (
                  <div className="img-select">
                    <img
                      className="img-jogador"
                      src={jogadorSelectFoto ?? fotoDey}
                      alt="player"
                    />
                    <p>{selectedJogador.jog_nome}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        {jogadoresTime2?.length > 0 &&
          (time === "PRETO" || time === "SEM-COLETE") && (
            <div className="row">
              <div className="input-group">
                <label htmlFor="selectJogador">SELECIONE O JOGADOR:</label>
                <select
                  id="selectJogador"
                  onChange={handleSelect}
                  className="new-input"
                >
                  <option value="">Selecione</option>
                  {jogadoresTime2?.map((jogador) => (
                    <option key={jogador.id} value={jogador.id}>
                      {jogador.jog_nome}
                    </option>
                  ))}
                </select>
                {selectedJogador && (
                  <div className="img-select">
                    <img
                      className="img-jogador"
                      src={jogadorSelectFoto ?? fotoDey}
                      alt="player"
                    />
                    <p>{selectedJogador.jog_nome}</p>
                  </div>
                )}
              </div>
            </div>
          )}

        <div className="button-row">
          <button type="submit" className="save-button">
            Confirmar
          </button>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          action={
            <React.Fragment>
              <IconButton
                size="medium"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          style={{
            backgroundColor:
              msg?.includes("Token") ||
              msg?.includes("encontrado") ||
              msg?.includes("existe") ||
              msg?.includes("Informe")
                ? " #590c0c"
                : "green",
            color: "white",
            fontSize: "1rem",
            fontFamily: "var(--primaryFont)",
          }}
          message={msg}
        />
      </Snackbar>
    </>
  );
}

export default JogoModalCPJ;
