import resume from "../assets/pdf/cvMatheusGoulart2024.pdf";
import math from "../assets/png/friends/sazuvista.jpeg";

export const headerData = {
  name: "Matheus Goulart de Freitas",
  title: "Engenheiro de Computação",
  titleSecundary: "Desenvolvedor Full Stack",
  desciption:
    "Posso construir e desenvolver o sistema ou site que você quiser! 😃",
  desciptionSecundary:
    "Só não faço aplicativos para celulares e nem robô de Pix. 🥴",
  image: math,
  resumePdf: resume,
};
